import { Box, Button, Grid, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import '../../../styles/components/tabs.scss';
import { styles } from '../tabs';
import { ApiContext } from '../useLogin';

export const CheckEmail = (props) => {
    const { onComplete } = props;
    const { email, setEmail } = useContext(ApiContext);
    const { showEmailInput, setShowEmailInput } = props.showInput;
    const mediaMatch = window.matchMedia('(min-width: 500px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
    const [error, setError] = useState(false);
    const emailRegex = /\S+@\S+\.\S+/;

    useEffect(() => {
        setEmail({
            ...setEmail,
            clientEmail: null,
        });
    }, []);

    const isEmail = (email) => {
        return emailRegex.test(email);
    };

    const userInput = {
        name: 'Email',
        userInputOptions: {
            disabled: false,
            required: true,
            label: 'Email',
            hint: 'Ej: ejemplo@gmail.com',
        },
    };

    const [isValid, setIsValid] = useState(false);

    const handleEmailChanged = (event) => {
        if (isEmail(event.target.value)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
        setEmail({
            ...setEmail,
            clientEmail: event.target.value.trim(),
        });
        setError(false);
    };

    const validateInput = () => {
        if (isValid) {
            setShowEmailInput(false);
            onComplete();
        } else {
            setError(true);
        }
    };

    const handleChangeEmailClicked = () => {
        setShowEmailInput(true);
    };

    return (
        <>
            {showEmailInput && (
                <Box className="mt-2-5">
                    <Grid container direction="row" align="center">
                        <Grid item xs={12}>
                            <h4 className="formEntryLabel">Ingresa tu email</h4>
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8}>
                            <TextField
                                disabled={userInput.userInputOptions.disabled}
                                error={error}
                                fullWidth={true}
                                helperText={error ? 'Requerido. Cargá un email válido' : userInput.userInputOptions.hint}
                                label={userInput.userInputOptions.label}
                                name={userInput.name}
                                onChange={handleEmailChanged}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} lg={4} className="fixedBottomOnlyMobile">
                            <Grid container xs={10} sm={11}>
                                <Button
                                    style={styles.container(matches)}
                                    variant="contained"
                                    color="primary"
                                    onClick={validateInput}
                                    disabled={!email.clientEmail}>
                                    Siguiente
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}{' '}
            {!showEmailInput && (
                <Box className="mb-1 mt-1">
                    <strong className="fs-14 fw-600 color-primary"> Email {email.clientEmail} - </strong>{' '}
                    <a href="#" className="link fs-14 fw-400 color-link-blue" onClick={handleChangeEmailClicked}>
                        CAMBIAR
                    </a>
                </Box>
            )}
        </>
    );
};
