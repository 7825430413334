import { Box, Button, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import MaskedInput from 'react-text-mask';
import { GOOGLE_RECAPTCHA_KEY, getEnv } from '../../../env';
import '../../../styles/components/tabs.scss';
import { styles } from '../tabs';
import { ApiContext } from '../useLogin';

const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput {...other} ref={inputRef} mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} placeholderChar={'\u2000'} />
    );
};

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export const CheckPhone = (props) => {
    const { onComplete } = props;
    const { showPhoneInput, setShowPhoneInput } = props.showInput;
    const mediaMatch = window.matchMedia('(min-width: 500px)');
    const [matches, setMatches] = useState(mediaMatch.matches);
    const { number, setNumber } = useContext(ApiContext);
    const [phoneError, setPhoneError] = useState(false);

    useEffect(() => {
        setNumber({
            ...setNumber,
            clientNumber: null,
        });
    }, []);

    // cuit handling
    const { clientNumber } = number;

    // captcha handling
    const CAPTCHA_KEY = getEnv(GOOGLE_RECAPTCHA_KEY);
    let recaptcha = React.useRef(null);

    const handleChangeNumber = (e) => {
        let phoneNumber = e.target.value.trim();

        setNumber({
            ...setNumber,
            [e.target.name]: phoneNumber,
            clientNumber: phoneNumber,
        });

        setPhoneError(false);
    };

    const validateInput = () => {
        if (clientNumber.length >= 10 && !/^15/.test(clientNumber)) {
            if (getEnv(GOOGLE_RECAPTCHA_KEY)) {
                recaptcha.execute();
            }
            onComplete();
            setShowPhoneInput(false);
        } else {
            setPhoneError(true);
        }
    };

    const handleChangePhoneClicked = () => {
        setShowPhoneInput(true);
    };

    return (
        <>
            {showPhoneInput && (
                <Box className="mt-2-5">
                    <Grid container direction="row" align="center">
                        <Grid item xs={12}>
                            <h4 className="formEntryLabel">
                                Ingresá tu número de celular. Te enviaremos un SMS con un código de 4 dígitos para validarlo.
                            </h4>
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8}>
                            <TextField
                                error={phoneError}
                                fullWidth={true}
                                helperText={
                                    phoneError ? 'Formato de celular inválido. Volvé a ingresarlo' : 'Código de área sin 0 + Nº.Ejemplo: 1123456789'
                                }
                                id="cell"
                                InputProps={{ inputComponent: TextMaskCustom }}
                                label="Celular"
                                name="phone"
                                onChange={handleChangeNumber}
                                value={clientNumber}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} lg={4} className="fixedBottomOnlyMobile">
                            <Grid container xs={10} sm={11}>
                                <Button
                                    style={styles.container(matches)}
                                    variant="contained"
                                    color="primary"
                                    onClick={validateInput}
                                    disabled={!number.phone}>
                                    Siguiente
                                </Button>
                                {getEnv(GOOGLE_RECAPTCHA_KEY) && (
                                    <div className="recaptcha-custom">
                                        <Recaptcha
                                            ref={(ref) => (recaptcha = ref)}
                                            sitekey={getEnv(GOOGLE_RECAPTCHA_KEY)}
                                            onResolved={validateInput}
                                        />
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}
            {!showPhoneInput && (
                <Box className="mb-1 mt-1">
                    <strong className="fs-14 fw-600 color-primary"> Celular {clientNumber} - </strong>{' '}
                    <a href="#" className="link fs-14 fw-400 color-link-blue" onClick={handleChangePhoneClicked}>
                        CAMBIAR
                    </a>
                </Box>
            )}
        </>
    );
};
