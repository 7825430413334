import { Box, Grid, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import Recaptcha from 'react-google-invisible-recaptcha';
import { useTranslation } from 'react-i18next';
import { GOOGLE_RECAPTCHA_KEY, getEnv } from '../../../env';
import eventTrackingService from '../../../services/eventtracking.service';
import pinRequestService from '../../../services/pinRequest.service';
import '../../../styles/components/tabs.scss';
import Toaster from '../../alerts/Toaster';
import { ApiContext } from '../useLogin';

const MAX_WAIT_TIME = 60;

export const VerifyPin = (props) => {
    const { request, number, pin, setPin } = useContext(ApiContext);
    const [seconds, setSeconds] = useState(MAX_WAIT_TIME);
    const [validationCodeSentSms, setValidationCodeSentSms] = useState(false);
    const [retryCodeSentSms, setRetryCodeSentSms] = useState(false);
    const [token, setToken] = useState(null);
    // Error handling
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    // Analytics
    const analyticsStep = 2;
    // captcha handling
    const CAPTCHA_KEY = getEnv(GOOGLE_RECAPTCHA_KEY);
    let recaptcha = React.useRef(null);
    // cuit handling
    const { clientNumber } = number;
    const { cuit } = request;
    const [secret, setSecret] = useState(null);
    const VALIDATION_PIN_SEND_EVENT = 'enviar código de validación sms';

    useEffect(() => {
        setPin({ clientPin: null });
        setSecret('');
        if (getEnv(GOOGLE_RECAPTCHA_KEY)) {
            recaptcha.execute();
        } else {
            handleSendPin(null);
        }
    }, []);

    useEffect(() => {
        if (token) {
            handleSendPin(token);
        }
    }, [token]);

    useEffect(() => {
        if (seconds <= 0) {
            setRetryCodeSentSms(true);
            setValidationCodeSentSms(false);
            setSeconds(MAX_WAIT_TIME);
        }
    }, [seconds]);

    const handleChangePin = (value) => {
        setPin({
            clientPin: value,
        });
    };

    useEffect(() => {
        if (validationCodeSentSms) {
            const timer = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    });

    const onResolved = (tkn) => {
        setToken(tkn);
    };

    const validateCaptcha = () => {
        if (clientNumber.length >= 10 && !/^15/.test(clientNumber)) {
            if (getEnv(GOOGLE_RECAPTCHA_KEY)) {
                recaptcha.execute();
            } else {
                handleSendPin(null);
            }
        }
    };
    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleSendPin = async (tkn) => {
        eventTrackingService.trackEvent(VALIDATION_PIN_SEND_EVENT, null, null, analyticsStep);
        pinRequestService
            .createRequest(`${cuit}`, `${clientNumber}`, tkn)
            .then(async (pinResponse) => {
                if (pinResponse.data) {
                    await setPin({ clientPin: pinResponse.data });
                    await setSecret(`[${pinResponse.data}]`);
                } else {
                    await setPin({ clientPin: '' });
                    await setSecret('');
                }
                await setValidationCodeSentSms(true);
            })
            .catch(function (error) {
                setPin({ clientPin: '' });
                setSecret('');
                setValidationCodeSentSms(false);
                setMessage(t(`WORKFLOW_ERROR.ERROR_SENDING_SMS.DESCRIPTION`));
                setRetryCodeSentSms(true);
                setOpen(true);
            });
    };

    return (
        <>
            <Box className="mt-2-5">
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <h4 className="formEntryLabel">Ingresá el código que te enviamos por SMS {secret}</h4>
                        {!retryCodeSentSms && <Typography>(Aguardá {seconds} segundos para reenviar)</Typography>}
                    </Grid>

                    {retryCodeSentSms && !validationCodeSentSms && (
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Box className="mt-1">
                                    <a href="#" className="link fs-14 mt-1 fw-400 color-link-blue" onClick={validateCaptcha}>
                                        Reenviar CÓDIGO
                                    </a>
                                </Box>
                            </Grid>
                            {getEnv(GOOGLE_RECAPTCHA_KEY) && (
                                <Grid item xs={12}>
                                    <div className="recaptcha-custom">
                                        <Recaptcha ref={(ref) => (recaptcha = ref)} sitekey={getEnv(GOOGLE_RECAPTCHA_KEY)} onResolved={onResolved} />
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    )}

                    {validationCodeSentSms && (
                        <Grid item xs={12} sm={8} lg={8}>
                            <ReactCodeInput
                                fields={4}
                                style={{ marginTop: '20px' }}
                                onChange={handleChangePin}
                                value={pin.clientPin}
                                autoComplete="false"
                                filterChars="qwertyuiopasdfghjklñzxcvbnm, .-QWERTYUIOPASDFGHJKLÑZXCVBNM{}[]+´|@"
                                inputMode="numeric"
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />{' '}
        </>
    );
};
